
import React from 'react';
import { connect } from 'react-redux';
import { graphql } from 'gatsby';
import Layout from '../layouts/Index';
import HowItWorks from '../components/HowItWorks';
import Concept from '../components/Concept';
import PressReleases from '../components/Banners/PressReleases';
import Testimonials from '../components/Banners/Testimonials';
import HomeBanner from '../components/Banners/HomeBanner';
import Books from '../components/Banners/Books';
import BlogPosts from '../components/Banners/BlogPosts';
import PreFooter from '../components/PreFooter'
import SEO from '../components/SEO';

import { handleModal } from '../actions/modal';
import { handleModalLanding } from '../actions/modalLanding';

class Index extends React.Component {

  componentDidMount() {
    const featuredLandingPage = this.props.data.popupLandingStory.edges.filter((item) => {
      return JSON.parse(item.node.data.featured_content);
    });

    const landingStory = featuredLandingPage.length > 0 ? (() => {
        const story = featuredLandingPage[0].node.data.stories[0].story.document[0].data;
        return {
          id: story.project_id,
          projectId: story.project_id,
          name: story.title.text,
          isBook: story.physical_book,
          popupBackground: story.popup_background,
          pageTracking: 'homepage'
        }
      }
    ) : (() => {});

    this.landingStory = landingStory();

    setTimeout(() => {
      window.addEventListener('scroll', this.handleLandingPopup, { passive: true });
    }, 1000);
  }

  handleLandingPopup = (e) => {
    
    if (typeof window === 'undefined' || !window.__popupTrigger || typeof this.landingStory === 'undefined') {
      return false;
    }
    
    if (!this.props.modalLanding) {
      if (window.scrollY > 620) {
        window.removeEventListener('scroll', this.handleLandingPopup, { passive: true });
        this.props.handleModalLanding({ modalLanding: true })
        this.props.handleModal({
          modal: !this.props.modal,
          story: this.landingStory
        });
      }
    }
  }

  render() {
    const {
      data: { 
        banner, 
        howItWorks, 
        concept, 
        books, 
        testimonials, 
        pressReleases, 
        blogPosts, 
        staticText,
        sectionRight,
        sectionLeft
      }
    } = this.props;
    const staticPrismicTexts = staticText.edges[0].node.data.texts;
    return (
      <Layout 
        twic={this.props.pageContext.twic} 
        context={this.props.pageContext}
        _t={staticPrismicTexts}
      >
        <SEO 
          path={this.props.path} 
          context={this.props.pageContext} 
        />
        <HomeBanner 
          twic={this.props.pageContext.twic} 
          {...banner} 
          context={this.props.pageContext} 
        />
        <HowItWorks 
          animate={false} 
          {...howItWorks} 
          {...{ homepage: true }} 
          twic={this.props.pageContext.twic} 
          context={this.props.pageContext} 
        />
        <Concept 
          prismic_cdn={this.props.pageContext.prismic_cdn} 
          {...concept} 
          context={this.props.pageContext} 
          background={{right: sectionRight, left: sectionLeft}}
        />
        <Books 
          {...books} 
          context={this.props.pageContext} 
          _t={staticPrismicTexts}
        />
        <Testimonials 
          twic={this.props.pageContext.twic} 
          {...testimonials} 
          context={this.props.pageContext} 
        />
        <PressReleases 
          {...pressReleases} 
          context={this.props.pageContext} 
        />
        {
          this.props.pageContext.locale === 'fr-fr' && <BlogPosts {...blogPosts} context={this.props.pageContext} />
        }
        <PreFooter locale={this.props.pageContext.locale} context={this.props.pageContext}  />
      </Layout>
    );
  };
};

export const pageQuery = graphql`
  query homePage($locale: String!) {
    banner: allPrismicSectionBanner(filter: { lang: { eq: $locale} }) {
      edges { ...banner }
    }
    howItWorks: allPrismicSectionHowitworks(filter: { lang: { eq: $locale} }) {
      edges { ...howItWorks }
    }
    concept: allPrismicSectionConcept(filter: { lang: { eq: $locale} }) {
      edges { ...concept }
    }
    books: allPrismicBlockStoryslider(filter: { lang: { eq: $locale} }) {
      edges { ...books }
    }
    testimonials: allPrismicSectionTestimonials(filter: { lang: { eq: $locale} }) {
      edges { ...testimonials }
    }
    pressReleases: allPrismicSectionPressreleases(filter: { lang: { eq: $locale} }) {
      edges { ...pressReleases }
    }
    blogPosts: allPrismicBlogPost(filter: { lang: { eq: $locale} }) {
      edges { ...blogPosts }
    }
    popupLandingStory: allPrismicPageLanding(
      filter: {
        lang: { eq: $locale }
        data: {
          enabled: {eq: "true"}
        }
      }
    ) {
      edges {
        node {
          lang
          data {
            featured_content
            stories {
              story {
                document {
                  data {
                    project_id
                    title {
                      text
                    }
                    physical_book
                    popup_background {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    staticText: allPrismicGlobalStatictexts(filter: { lang: { eq: $locale} }) {
      edges { ...StaticTexts }
    }
    sectionRight: allFile(filter: {relativePath: {eq: "conceptright.png"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
    sectionLeft: allFile(filter: {relativePath: {eq: "conceptleft.png"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`;

const mapStateToProps = (state) => ({
  ...state.modal,
  ...state.modalLanding,
  ...{ viewportSize: state.viewport.viewportSize }
});

const mapDispatchToProps = (dispatch) => ({
  handleModal: (state) => dispatch(handleModal(state)),
  handleModalLanding: (state) => dispatch(handleModalLanding(state))
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);